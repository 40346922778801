import React from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MyTextField } from '.';


const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '300px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  street: {
    flexGrow: 0,
    flexShrink: 1,
    marginTop: 0,
    marginBottom: 0,
  },
  row2: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexGrow: 0,
    flexShrink: 1,
    marginTop: 0,
    marginBottom: 0,
  },
  city: {
    flexGrow: 1,
    flexShrink: 1,
    marginTop: 0,
    marginBottom: 0,
  },
  state: {
    width: '30px',
    flexGrow: 0,
    flexShrink: 0,
    marginLeft: '5px',
    marginTop: 0,
    marginBottom: 0,
  },
  zip: {
    marginLeft: '5px',
    width: '100px',
    flexGrow: 0,
    flexShrink: 0,
    marginTop: 0,
    marginBottom: 0,
  },
});
const useStyles = makeStyles(styles);


export const AddressField = ({
                    label,
                    value,
                    onValueChange,
                    disabled,
                    className,
                    ...otherProps
                  }) => {
  const classes = useStyles();

  const handleOnChange = (n, v) => {
    if( onValueChange ) {
      const newAddress = JSON.parse(JSON.stringify(value));
      newAddress[n] = v;
      onValueChange(newAddress);
    }
  }

  return (
    <div className={clsx(classes.container, className)} {...otherProps} >
      <MyTextField      
        label={label}    
        value={value?.street || ''}
        onValueChange={(value) => handleOnChange('street', value)}
        disabled={disabled}
        className={classes.street}
      />
      <div className={classes.row2}>
        <MyTextField          
          value={value?.city || ''}
          onValueChange={(value) => handleOnChange('city', value)}
          disabled={disabled}
          className={classes?.city}
        />
        <MyTextField
          value={value?.state || ''}
          onValueChange={(value) => handleOnChange('state', value)}
          disabled={disabled}
          className={classes.state}
        />
        <MyTextField          
          value={value?.zip || ''}
          onValueChange={(value) => handleOnChange('zip', value)}
          disabled={disabled}
          className={classes.zip}
        />
      </div>
    </div>
  );
}