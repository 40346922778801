import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';




const MyButton = ({onClick, ...otherProps}) => {
	return (
		<Button onClick={(e) => { e.preventDefault(); onClick(e); }} {...otherProps} />
	);
}


export const SquareIconButton = withStyles(theme => ({
  root: { 
    minWidth: 'initial'
  }
}), { withTheme: true })(MyButton);


export const AddButton = ({onAdd, label: labelProp, ...otherProps}) => {
	const label = labelProp || 'Add';
	return (
		<MyButton onClick={(e) => onAdd()} startIcon={<AddIcon />} {...otherProps} >{label}</MyButton>
	);
}


export const DeleteButton = ({onDelete, ...otherProps}) => {
	return (
		<SquareIconButton onClick={(e) => onDelete()} startIcon={<DeleteIcon />} {...otherProps} />
	);
}


export const EditButton = ({onEdit, ...otherProps}) => {
	return (
		<SquareIconButton onClick={(e) => onEdit()} startIcon={<EditIcon fontSize='small' />} {...otherProps} />
	);
}
