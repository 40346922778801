// React
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Radio, RadioGroup, FormControlLabel, FormControl, InputLabel } from '@material-ui/core';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  radioGroup: {
    marginTop: '12px',
    marginBottom: '-6px',
  }
}));


export const RadioGroupField = (props) => {
  const {
    label,
    direction,
    value,
    onValueChange,
    options,       // options: [ {label: 'Red', value: 'red' } ]
    disabled,
    className: classNameProp,
    ...otherProps
  } = props;

  const classes = useStyles();

  const handleOnChange = (event) => {
    event.preventDefault();
    if( onValueChange )
      onValueChange(event.target.value);
  }

  if( typeof value === 'number' )
    throw new Error('Property "value" is of type number.  Please use a string.');

  return (
    <FormControl className={clsx(classes.formControl, classNameProp)} {...otherProps} >
      <InputLabel shrink filled >{label}</InputLabel>
      <RadioGroup   {...(direction === 'column' ? {} : {row:true})}
                  value={value}
                  onChange={(event) => handleOnChange(event)}
                  disabled={disabled}
                  className={classes.radioGroup}
                  > 
      {
        options.map( (option) => {
          if( typeof option.value === 'number' )
            throw new Error('One of the options values is type number.  Please use strings.');
          return ( <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} disabled={disabled} /> );
        } )
      }
      </RadioGroup>
    </FormControl>
  );
}








