// React
import React, { useState, useEffect } from 'react';
// Material UI
import { Typography, Button, Dialog, AppBar, Toolbar, IconButton, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
// Other Thrid-Party
// Local
import Header from './Header';
import Footer from './Footer';
import { CurrencyTextField, IntegerTextField, SelectField } from './controls';
import { setDeep } from './Util';


const styles = (theme) => ({


  dialog: {
    backgroundColor: theme.palette.background.default,
  },

  contentContainer: {
    width: 'min(calc(100% - 16px), 800px)',
    marginLeft: 'auto',
    marginRight: 'auto',

    paddingTop: 'calc( 16px + 3vw)',
    paddingBottom: 60,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  quoteSection: {

    color: 'black',
    backgroundColor: theme.palette.background.default,

    '& > div': {        // Content
      '& > div': {      // Sub-section (Your Plan or Your Quote)
        width: '100%',
        marginTop: 32, 
        display: 'flex', 
        flexDirection: 'column',

        '& > div': {    // Fields box
          marginTop: 8, 
          //minHeight: 150,

          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'top',
          flexWrap: 'wrap',

          backgroundColor: 'white',
          color: 'black',

          '& > div': {
            width: '50%',
            minWidth: 350,
            padding: 16,

            display: 'flex', 
            flexDirection: 'column',
          }

        }
      }
    }

  },

  section: {

    '& li': {
      paddingLeft: 0,

      '& svg': {
        marginRight: 8,
      },

      '& > svg': {
        marginTop: -2,
        marginRight: 8,
        fontSize: 'large',
        stroke: theme.palette.primary.main,
        strokeWidth: 3
      }
    }
  }, 

  filler: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  }

});
const useStyles = makeStyles(styles);



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuotePopup({open, onClose}) {
  const classes = useStyles();
  const [data, setData] = useState({optPayPointsAndInterest: 'when-due', optInterestAccruesOn: 'full'});
  const [quote, setQuote] = useState(undefined);

  useEffect(() => {

    // Calculate quote
    const q = {};
    if( isNaN(data.purchasePrice) || isNaN(data.rehabBudget) || isNaN(data.arv)
          || data.numSimilarProjects3yrs === undefined || data.creditScore === undefined )
      return undefined;

    if( (data.purchasePrice + data.rehabBudget) / data.arv > 0.8 )
      q.canLend = false;
    else if( ['first-ever','first-recent','1'].includes(data.numSimilarProjects3yrs) || data.creditScore < 620 ) {
      q.canLend = true;
      q.purchasePct = 85;
      q.arvPct = 55;
    }
    else if( data.numSimilarProjects3yrs === '6+' || data.creditScore > 750 ) {
      q.canLend = true;
      q.purchasePct = 95;
      q.arvPct = 65;
    }
    else {
      q.canLend = true;
      q.purchasePct = 90;
      q.arvPct = 60;
    }
    if( q.canLend ) {
      const loanAmtByARV = q.arvPct * data.arv / 100;
      q.constructionAmt = Math.min(data.rehabBudget, loanAmtByARV);
      const remLoanAmtByARV = Math.max(loanAmtByARV - q.constructionAmt, 0)
      q.purchaseAmt = Math.min( data.purchasePrice * q.purchasePct / 100, remLoanAmtByARV );

      /*
      q.purchaseAmt = Math.min(data.purchasePrice * q.purchasePct / 100, loanAmtByARV);
      const remLoanAmtByARV = Math.max(loanAmtByARV - q.purchaseAmt, 0);
      q.constructionAmt = Math.min( data.rehabBudget, remLoanAmtByARV );
      */
      q.totalLoan = q.purchaseAmt + q.constructionAmt;

      q.rate = '11.5%';
      q.points = (1.5 + (data.optInterestAccruesOn === 'disbursed' ? 1 : 0) + (data.optPayPointsAndInterest === 'defer' ? 1 : 0))
                * Math.max(q.totalLoan, 100000) / 100;
      q.payments = data.optPayPointsAndInterest === 'when-due' ? 'Monthly interest-only' : 'None';
      q.term = '12 months';

      q.cashToClose = Math.round(data.purchasePrice 
                          - q.purchaseAmt 
                          + q.points 
                          + q.totalLoan * 0.0056  // Title Premium
                          + data.purchasePrice * 0.02  // Tax
                          + 1000  // Misc
                          + data.rehabBudget * 0.05 );  // Contingency
    }

    setQuote(q);
  }, [data]);


  const handleOnChange = (name, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    setDeep( newData, name, value );
    setData( newData );
  };


  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} className={classes.dialog} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Quote
          </Typography>
        </Toolbar>
      </AppBar>
      
        <div className={classes.quoteSection} >
          <div className={classes.contentContainer} >

            <Typography variant='body2' style={{marginTop: 16, fontStyle: 'italic', textAlign: 'center'}} >Quotes are preliminary and not a guarantee of a loan.</Typography>

            <div className={classes.section} >
              <Typography variant='h4'>You and your project</Typography>
              <div>
                <div>
                  <SelectField
                    label="What is your plan for the property?"
                    options={[{value: 'flip', label: 'Fix & Flip'}, 
                              {value: 'rehab-rent', label: 'Rehab & Rent'}, 
                              {value: 'other', label: 'Other'}]}
                    value={data.plan}
                    onValueChange={(value) => handleOnChange("plan", value)}
                    style={{width: 300}}
                  />
                  <SelectField
                    label="Have you done this before?"
                    options={[{value: 'first-ever', label: "It's my first one ever"}, 
                              {value: 'first-recent', label: "It's my first one in 3 years"}, 
                              {value: '1', label: 'Finished 1 in the last 3 years'}, 
                              {value: '2-5', label: 'Finished 2-5 in the last 3 years'}, 
                              {value: '6+', label: 'Finished 6+ in the last 3 years'}]}
                    value={data.numSimilarProjects3yrs}
                    onValueChange={(value) => handleOnChange("numSimilarProjects3yrs", value)}
                    style={{width: 300}}
                  />
                  <IntegerTextField
                    label="Credit Score"
                    value={data.creditScore || ''}
                    onValueChange={(value) => handleOnChange("creditScore", value)}
                    style={{width: 220}}
                  />
                </div>
                <div>
                  <CurrencyTextField
                    label="Purchase Price"
                    value={data.purchasePrice}
                    onValueChange={(value) => handleOnChange("purchasePrice", value)}
                  />
                  <CurrencyTextField
                    label="Rehab Budget"
                    value={data.rehabBudget}
                    onValueChange={(value) => handleOnChange("rehabBudget", value)}
                  />
                  <CurrencyTextField
                    label="After-Rehab Value"
                    value={data.arv}
                    onValueChange={(value) => handleOnChange("arv", value)}
                  />
                </div>
              </div>
            </div>

            <div className={classes.section} >
              <Typography variant='h4'>Options</Typography>
              <div>
                <div>
                  <SelectField
                    label="Interest accures on"
                    options={[{value: 'full', label: 'The full loan amount'}, 
                              {value: 'disbursed', label: 'Only the portion disbursed (+1pt)'}]}
                    value={data.optInterestAccruesOn}
                    onValueChange={(value) => handleOnChange("optInterestAccruesOn", value)}
                    style={{width: 300}}
                  />
                </div>
                <div>
                  <SelectField
                    label="Defer points and interest to payoff?"
                    options={[{value: 'when-due', label: 'No'}, 
                              {value: 'defer', label: 'Yes (+1pt)'}]}
                    value={data.optPayPointsAndInterest}
                    onValueChange={(value) => handleOnChange("optPayPointsAndInterest", value)}
                    style={{width: 300}}
                  />
                </div>
              </div>
            </div>

            <div className={classes.section} >
              <Typography variant='h4'>Our Quote</Typography>
              { quote === undefined ?
                <div className={classes.section} style={{justifyContent: 'center'}} >
                  <Typography variant='body1' style={{textAlign: 'center', margin: 16}} >Pending...</Typography>
                </div>
                : quote.canLend ? 
                <div>
                  <div >
                    <ReadOnlyField label='Total loan' value={'$' + quote.totalLoan.format0()} />
                    <ReadOnlyField label='At closing' value={'$' + quote.purchaseAmt.format0()} />
                    <ReadOnlyField label='Const draws' value={'$' + quote.constructionAmt.format0()} />
                  </div>
                  <div>
                    <ReadOnlyField label='Term' value={quote.term} />
                    <ReadOnlyField label='Rate' value={quote.rate} />
                    <ReadOnlyField label='Points' value={'$' + quote.points.format0()} />
                    <ReadOnlyField label='Monthly Payments' value={quote.payments} />
                  </div>
                </div>
                :
                <div className={classes.section} style={{justifyContent: 'center'}} >
                  <Typography variant='body1' style={{textAlign: 'center'}} >We're sorry but that doesn't meet our lending criteria.</Typography>
                </div>
              }

            </div>
            
            { quote && quote.canLend &&
              <div className={classes.section} >
                <Typography variant='h3' >Like what you see?</Typography>
                <Typography variant='body1' style={{textAlign: 'center'}} >Let's get you moving</Typography>
                
                <div style={{ marginTop: 48, minHeight: 'inherit', backgroundColor: 'inherit', justifyContent: 'space-evenly' }} >
                  <PhoneButton size='large' />
                  <EmailButton size='large' />
                </div>

              </div>
            }

          </div>
        </div>

        <div className={classes.filler} />
    </Dialog>


  );
};



const PhoneButton = ({size}) => {
  return ( <Button variant='contained' color='secondary' size={size} href='tel:2672091630'  style={{width: 170, fontWeight: 'bold'}} >Call Now</Button>)
}

const EmailButton = ({size}) => {
  return ( <Button variant='contained' color='secondary' size={size} href='mailto:info@entrywayloans.com'  style={{width: 170, fontWeight: 'bold'}} >Email Now</Button>)
}


const ReadOnlyField = ({label, value, ...otherProps}) => {

  return (
    <div style={{ width: '100%', height: 40, display: 'flex', flexDirection: 'row', fontSize: '16px', alignItems: 'center' }} {...otherProps} >
      <div style={{ width: '50%' }} >{label + ':'}</div>
      <div style={{ width: '50%', fontWeight: 'bold' }} >{value}</div>
      {}
    </div>
  );

}

