// React
import React from 'react';
// Material UI
import {  Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Other Thrid-Party
// Local



const styles = (theme) => ({
  legalSection: {
    //height: 100,
    padding: 8,

    color: 'white',
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',

    '& > div': {
      padding: 0,
    }
  },

});
const useStyles = makeStyles(styles);


export default function Header({contentClassName}) {
  const classes = useStyles();


  return (
    
    <div className={classes.legalSection} >
      <div className={ classes.contentContainer } > 
        <Typography variant='body2' style={{color: 'rgba(255,255,255,0.5)'}} >Copyright Entryway Loans LLC 2021 | All Rights Reserved</Typography>
      </div>
    </div>
  );
}

