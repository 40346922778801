import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2b5190',
    },
    secondary: {
      main: '#9a2a1b',
    },
    background: {
      default: '#EFF4F7',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Merriweather',
      fontSize: 'calc( max(min(3vw, 48px), 12px) + 24px)',
      fontWeight: 400,
      lineHeight: 1.3,
      letterSpacing: '1px',
      textAlign: 'center',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: 'calc( max(min(1.33vw, 20px), 12px) + 9px)',
      fontWeight: 400,
      marginTop: 24,
      marginBottom: 24,
      letterSpacing: '1px',
      textAlign: 'center',
    },

    h2: {
      fontFamily: 'Merriweather',
      fontSize: 'calc( max(min(2.5vw, 36px), 16px) + 12px)',
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: '1px',
      textAlign: 'center',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: 'calc( max(min(1.33vw, 20px), 12px) + 9px)',
      fontWeight: 400,
      marginTop: 16,
      marginBottom: 16,
      letterSpacing: '1px',
      textAlign: 'center',
    },

    h3: {
      fontFamily: 'Roboto',
      fontSize: 'calc( max(min(1.5vw, 25px), 12px) + 9px)',
      fontWeight: 400,
      marginTop: 16,
      marginBottom: 16,
      letterSpacing: '1px',
      textAlign: 'center',
    },
    h4: {
      fontFamily: 'Roboto',
      fontSize: '1.250rem',
      letterSpacing: '1px',
      fontWeight: 500,
    },
    body1: {
      fontFamily: 'Roboto',
      fontSize: 'max(1.000rem, 18px)',
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'Roboto',
    }
  },
});

export default theme;