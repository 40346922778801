// React
import React from 'react';
// Material UI
import {  Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Other Thrid-Party
import clsx from 'clsx';
// Local



const styles = (theme) => ({

  header: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    zIndex: 100,
    backgroundColor: 'white',
  },
  outerContent: {
    height: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  innerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nav: {
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center',
    marginRight: '7vw',
  },

});
const useStyles = makeStyles(styles);


const NavButtonStyled = withStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    margin: '1vw',
    '&:hover': {
      backgroundColor: 'rgba(43,81,144,0.5)',
    }
  }
}))(Button);
const NavButton = ({children, ...otherProps}) => {
  return ( <NavButtonStyled color='primary' {...otherProps} >{children}</NavButtonStyled>)
}

const PhoneButton = ({size}) => {
  return ( <Button variant='contained' color='secondary' size={size} href='tel:2672091630'  style={{fontWeight: 'bold'}} >267-209-1630</Button>)
}



export default function Header({contentClassName, onGotoHome, onGotoTerms, onGotoContact}) {
  const classes = useStyles();
  const theme = useTheme();
  const lt340 = useMediaQuery(theme.breakpoints.down(340));
  const lt800 = useMediaQuery(theme.breakpoints.down('800'));
  const height = lt800 ? 55 : 70;

  return (
    <React.Fragment >

      <div className={classes.header} style={{ height: height }} >
        <div className={ clsx(classes.outerContent, contentClassName) } style={{height: '100%'}} >
          <div className={classes.innerContent} >
          { lt340 ?
             <React.Fragment>
                <img src="images/Entryway-4color 242x45.png" alt='' width={145.2} height={27} onClick={() => onGotoHome()} />
                <PhoneButton size='medium' />
              </React.Fragment>
            : lt800 ?
             <React.Fragment>
                <img src="images/Entryway-4color 242x45.png" alt='' width={181.5} height={33.75} onClick={() => onGotoHome()} />
                <PhoneButton size='medium' />
              </React.Fragment>
            :
             <React.Fragment>
                <img src="images/Entryway-4color 242x45.png" alt='' width={242} height={45} onClick={() => onGotoHome()} />
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                  <nav className={classes.nav} >
                    <NavButton onClick={() => onGotoHome()} >Home</NavButton>
                    <NavButton onClick={() => onGotoTerms()} >Terms</NavButton>
                    <NavButton onClick={() => onGotoContact()} >Contact</NavButton>
                  </nav>
                  <PhoneButton size='large'/>
                </div>
              </React.Fragment>
          }
          </div>
        </div>
      </div>

      <div style={{ height: height, width: '100%' }} />

    </React.Fragment>
  );
}

