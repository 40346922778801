// React
import React, {useRef, useState} from 'react';
// Material UI
import { Typography, Button, List, ListItem, Card, CardContent } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {  Schedule as ScheduleIcon, 
          CalendarToday as CalendarTodayIcon, 
          Check as CheckIcon, 
          AttachMoney as AttachMoneyIcon,
          Call as CallIcon,
          MailOutline as MailOutlineIcon  } from '@material-ui/icons';
// Other Thrid-Party
import { navigate } from "@reach/router";
// Local
import Header from './Header';
import Footer from './Footer';
import QuotePopup from './QuotePopup';



const styles = (theme) => ({


  main: {
    width: 'auto',
    marginLeft: 0,
    marginRight: 0,
  },
  contentContainer: {
    width: 'min(calc(100% - 16px), 1200px)',
    marginLeft: 'auto',
    marginRight: 'auto',
    scrollMarginTop: '70px',

    paddingTop: 'calc( 16px + 3vw)',
    paddingBottom: 60,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentHeader: {
    width: 'min(calc(100% - 16px), 1200px)',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  titleSection: {
    height: 'calc( 100vh - 55px)',

    color: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',


    [theme.breakpoints.up('sm')]: {
      maxHeight: 'min(100vw, 1000px)',
      
      backgroundImage: 'url("images/Twin 1500x1501.jpg")',
      backgroundPositionY: 'center',
      backgroundPosition: '0px 0px',

      '& > div': {

        '& > h1': {
          textAlign: 'center',
          padding: 8,
        },

        '& > h3': {
          marginTop: 32,
          padding: 8,
        },

        '& > a': {
          marginTop: 32,
        }

      },
    },

    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc( 100vw * 1.7 )',

      backgroundImage: 'url("images/Twin 400x681.jpg")',
      backgroundPositionY: 'top',

      '& > div': {

        '& > h1': {
          textAlign: 'center',
          padding: 8,
        },

        '& > h6': {
          display: 'none',
        },

        '& > a': {
          marginTop: 'calc( 30vw - 66px )',
        }

      }
    }

  },

  commitmentSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > div': {
      '& > div': {
        width: '100%',

        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'space-evenly', 
        flexWrap: 'wrap', 
      }
    }
  },
  'commitmentCard': {
    marginTop: 60,
    maxWidth: 250,
    padding: '0px 8px 16px 8px',

    '& > div': {
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      padding: '0px 16px',


      '& > div': {
        position: 'absolute',
        marginTop: -33,
        width: 66,
        height: 66,

        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,

        '& > svg': {
          fontSize: 42,
          color: 'white',
          margin: 12,
        },

      },

      '& > h4': {
        marginTop: 48,
        marginBottom: 24,
      },
    },
  },

  termsSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',


    '& li': {
      paddingLeft: 0,

      '& svg': {
        marginTop: -2,
        marginRight: 8,
        fontSize: 'large',
        stroke: theme.palette.primary.main,
        strokeWidth: 3
      }
    }
  },
  'termsCard': {
    padding: 16,
  },

  quoteSection: {
    //height: 350,
    padding: '80px 0px 80px',

    color: 'white',
    backgroundColor: theme.palette.primary.main,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px 16px',

      '& > h3': {
        maxWidth: 550,
        textAlign: 'left',
        fontStyle: 'italic',
        letterSpacing: '2px',
        lineHeight: 1.4,
        fontWeight: 400,
      },

      '& > p:nth-of-type(1)': {
        marginTop: 24,
        fontFamily: 'Poppins',
        letterSpacing: '2px',
        fontWeight: 'bold',
        //fontSize: '0.9rem',
      },

      '& > p:nth-of-type(2)': {
        marginTop: 4,
        fontFamily: 'Poppins',
        fontSize: '0.75rem'
      }
    }
  },

  contactSection: {
    '& li': {
      paddingLeft: 0,

      '& svg': {
        marginRight: 8,
      },

      '& > svg': {
        marginTop: -2,
        marginRight: 8,
        fontSize: 'large',
        stroke: theme.palette.primary.main,
        strokeWidth: 3
      }
    }
  },

});
const useStyles = makeStyles(styles);


const WhiteButton = withStyles((theme) => ({
  root: {
    borderColor: 'white',
    borderWidth: '1.5px',
    fontWeight: 'bold',
    margin: 16,
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(43,81,144,0.8)',
    }
  }
}))(Button);


export default function Home() {
  const classes = useStyles();
  const refTitle = useRef(null);
  const refTerms = useRef(null);
  const refContact = useRef(null);
  const [quoteOpen, setQuoteOpen] = useState(false);

  return (
    <React.Fragment >
      <Header contentClassName={classes.contentHeader}
              onGotoHome={() => refTitle.current.scrollIntoView() }
              onGotoTerms={() => refTerms.current.scrollIntoView() }
              onGotoContact={() => refContact.current.scrollIntoView() } />

      <div className={classes.main} >

        <div className={classes.titleSection} >
          <div className={ classes.contentContainer } ref={refTitle} >
            <Typography variant='h1' >Financing for<br/>Real Estate Investors</Typography>
            <Typography variant='subtitle1' >Your next project starts here</Typography>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}} >
              <WhiteButton onClick={() => refTerms.current.scrollIntoView()} variant='outlined' size='large'>Learn More</WhiteButton>
              <WhiteButton onClick={() => setQuoteOpen(true)} variant='outlined' size='large'>Get A Quote</WhiteButton>
            </div>
          </div>
        </div>

        <div className={ classes.commitmentSection } >
          <div className={ classes.contentContainer } >
            <Typography variant='h2' >Our Commitment</Typography>
            <Typography variant='subtitle2' >To keep you on track</Typography>

            <div>

              <Card className={classes.commitmentCard}>
                <CardContent>
                  <div><ScheduleIcon /></div>
                  <Typography variant='h4' >Fast approval</Typography>
                  <Typography variant='body1' >Loan approvals within 24 hours after your application is complete.</Typography>
                </CardContent>
              </Card>

              <Card className={classes.commitmentCard}>
                <CardContent>
                  <div><CalendarTodayIcon style={{fontSize: 38, margin: 14}}  /></div>
                  <Typography variant='h4' >Quick closing</Typography>
                  <Typography variant='body1' >Close as soon as five days after you accept the terms.</Typography>
                </CardContent>
              </Card>

              <Card className={classes.commitmentCard}>
                <CardContent>
                  <div><CheckIcon /></div>
                  <Typography variant='h4' >No surprises</Typography>
                  <Typography variant='body1' >Loan terms that you can rely on.  No last-minute changes.</Typography>
                </CardContent>
              </Card>

              <Card className={classes.commitmentCard}>
                <CardContent>
                  <div><AttachMoneyIcon /></div>
                  <Typography variant='h4' >Clear pricing</Typography>
                  <Typography variant='body1' >Just interest and points.  We don't charge processing fees.</Typography>
                </CardContent>
              </Card>

            </div>

          </div>
        </div>

        {/*  Quote Section */}
        <div className={ classes.quoteSection } >
          <div className={ classes.contentContainer } >
            <Typography variant='h3' >"Entryway Loans is responsive, honest, and easy to work with.  They understand my timelines and ensure the funding is ready when I need it."</Typography>
            <Typography variant='body1' >Matt W.</Typography>
            <Typography variant='body2' >Rehab & Hold Investor</Typography>
          </div>
        </div>


        {/*  Loan Programs Section */}
        <div className={ classes.termsSection } >
          <div className={ classes.contentContainer } ref={refTerms} >
            <Typography variant='h2' >Purchase & Rehab Loans</Typography>
            <Typography variant='subtitle2' >Designed for the investor</Typography>

            <div style={{ marginTop: 32, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap', width: '100%' }} >
              <Card className={classes.termsCard}>
                <CardContent>
                <Typography variant='h4' >Terms</Typography>
                <List style={{marginTop: 24}} >
                  <ListItem><CheckIcon /><Typography variant='body1' >$50k - $250k loan amount</Typography></ListItem>
                  <ListItem><CheckIcon /><Typography variant='body1' >12 months</Typography></ListItem>
                  <ListItem><CheckIcon /><Typography variant='body1' >11.5% annual interest</Typography></ListItem>
                  <ListItem><CheckIcon /><Typography variant='body1' >1.5 points ($1500 minimum)</Typography></ListItem>
                  <ListItem><CheckIcon /><Typography variant='body1' >Option to defer interest & points</Typography></ListItem>
                </List>
              </CardContent>
            </Card>
              <div style={{marginTop: 24}} >
                <Typography variant='h4' style={{fontWeight: '500'}} >Underwriting</Typography>
                <List style={{marginTop: 24}}>
                  <ListItem><CheckIcon /><Typography variant='body1' >80-95% purchase + 100% construction </Typography></ListItem>
                  <ListItem><CheckIcon /><Typography variant='body1' >55-70% after-rehab value</Typography></ListItem>
                  <ListItem><CheckIcon /><Typography variant='body1' >No recent foreclosures or bankruptcies</Typography></ListItem>
                  <ListItem><CheckIcon /><Typography variant='body1' >Cash to close and for contingencies</Typography></ListItem>
                </List>
              </div>
            </div>

            <div style={{marginTop: 42}}>
            <QuoteButtonRed onClick={() => setQuoteOpen(true)}  />
            </div>
            
          </div>

        </div>

        {/*  Quote Section */}
        <div className={ classes.quoteSection } >
          <div className={ classes.contentContainer } >
            <Typography variant='h3' >"I've been working with Entryway Loans for 3 years now. They’ve helped me go from 1 flip to 2-3 per year as well increase my margins by not being killed by lender fees."</Typography>
            <Typography variant='body1' >Anthony L.</Typography>
            <Typography variant='body2' >Fix & Flip Investor</Typography>
          </div>
        </div>

        <div className={ classes.contactSection} >
          <div className={ classes.contentContainer } ref={refContact} >
            <Typography variant='h2' >Get Started</Typography>
            <Typography variant='subtitle2' >Serving Greater Philadelphia and SE Pennsylvania</Typography>

            <div style={{ marginTop: 32, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: 'wrap', width: '100%' }} >
              <Card className={classes.termsCard} style={{paddingBottom: 0}} >
                <CardContent>
                  <Typography variant='h4' >Reach Out</Typography>
                  <List style={{marginTop: 12}} >
                    <ListItem><Button href='tel:2672091630' ><CallIcon /><Typography variant='body1' >267-209-1630</Typography></Button></ListItem>
                    <ListItem><Button href='mailto:info@entrywayloans.com' ><MailOutlineIcon /><Typography variant='body1' style={{textTransform: 'lowercase'}} >info@entrywayloans.com</Typography></Button></ListItem>
                  </List>
                </CardContent>
              </Card>
              <div >
                <List style={{marginTop: 12}}>
                  <ListItem><CheckIcon /><Typography variant='body1' >Discuss a project</Typography></ListItem>
                  <ListItem><CheckIcon /><Typography variant='body1' >Get a pre-approval</Typography></ListItem>
                  <ListItem><CheckIcon /><Typography variant='body1' >Confirm a quote</Typography></ListItem>
                  <ListItem><CheckIcon /><Typography variant='body1' >Start an application</Typography></ListItem>
                </List>
              </div>
            </div>
          </div>
        </div>

      <Footer contentClassName={classes.contentHeader}  />

      </div>

      <QuotePopup open={quoteOpen} onClose={() => setQuoteOpen(false)} />

    </React.Fragment>
  );
}


const QuoteButtonRed = ({size, onClick}) => {
  return ( <Button variant='contained' color='secondary' size='large' onClick={onClick} style={{width: 170, fontWeight: 'bold'}} >Get a Quote</Button>)
}

