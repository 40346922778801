import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import MyTextField from './MyTextField';

const useStyles = makeStyles((theme) => ({
  input: {
    textAlign: 'right'
  },
  field: {
    width: '150px',
  }
}));

export const IntegerTextField = React.forwardRef(({
                        value,
                        onValueChange,
                        inputProps,
                        InputProps,
                        className: classNameProp,
                        ...otherProps
                      }, ref) => {
  const classes = useStyles();

  const {
    className: inputPropClassName,
    ...otherinputProps
  } = inputProps || {};

  const {
    startAdornment,
    ...otherInputProps
  } = InputProps || {};

  if( startAdornment )
    throw new Error('Does not support startAdornment in InputProps property.');

  return (
    <NumberFormat 
        value={value || ''}
        onValueChange={(values) => { if( onValueChange ) onValueChange(values.value) } }
        thousandSeparator={true} 
        decimalScale={0}
        fixedDecimalScale={true}
        customInput={MyTextField}
        inputProps={{
          className: clsx(classes.input, inputPropClassName),
          ...otherinputProps
        }}
        InputProps={{
          ...otherInputProps
        }}
        className={clsx(classes.field, classNameProp)}
        {...otherProps}
      />
  )
});



export default IntegerTextField;