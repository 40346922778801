import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import MyTextField from './MyTextField';

const useStyles = makeStyles((theme) => ({
  input: {
    textAlign: 'right'
  },
  field: {
    width: '150px',
  }
}));

export const PercentTextField = React.forwardRef(({value,
                        onValueChange,
                        className: classNameProp,
                        inputProps,
                        InputProps,
                        errorText,
                        ...otherProps}, ref) => {
  const classes = useStyles();

  const {
    className: inputPropClassName,
    ...otherinputProps
  } = inputProps || {};

  const {
    startAdornment,
    ...otherInputProps
  } = InputProps || {};

  if( startAdornment )
    throw new Error('Does not support startAdornment in InputProps property.');

  //console.log(value);

  return (
    <NumberFormat 
        value={value || ''}
        onValueChange={(values) => { if( onValueChange ) onValueChange( parseFloat(values.value) ) } }
        thousandSeparator={true} 
        decimalScale={3}
        customInput={MyTextField}
        inputProps={{
          className: clsx(classes.input, inputPropClassName),
          ...otherinputProps
        }}
        InputProps={{
          endAdornment: (
                <InputAdornment position="end">%</InputAdornment>
              ),
          ...otherInputProps
        }}
        className={clsx(classes.field, classNameProp)}
        errorText={errorText}
        {...otherProps}
      />
  )
});



export default PercentTextField;