
import React from 'react';

import { Router } from "@reach/router"

import Home from './Home';
import NotFound from './NotFound';
import './App.css';


function App() {
  return (
    <React.Fragment>
      <Router style={{height: '100%'}}>
      	<Home path='/' />
      	<NotFound default />
    	</Router>

    </React.Fragment>
  );
}


export default App;
