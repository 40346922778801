import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';


const styles = (theme) => ({
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '300px',
  }
});
const useStyles = makeStyles(styles);



export const MyTextField = React.forwardRef(({
                                inputRef: inputRefProp,
                                onFocus: onFocusProp,
                                onBlur: onBlurProp,
                                onChange,
                                type: typeProp,
                                onValueChange,
                                className: classNameProp,
                                errorText,
                                ...otherProps }, ref) => {
  const classes = useStyles();
  const refTextField = useRef(null);
  const [inputRef, setInputRef] = useState(undefined);
  // eslint-disable-next-line
  const [i, setI] = useState(1);
  const [touched, setTouched] = useState(false);

  if( ref )
    throw new Error('Cannot accept ref parameter');

  // If there is a start adornment, override label shrinking to indent the label
  const labelEl = refTextField?.current?.getElementsByTagName('label')?.[0];
  if( labelEl && otherProps?.InputProps?.startAdornment ) {  
    const shrink = inputRef?.value?.length > 0 || document.activeElement === inputRef;
    labelEl.style.transform = shrink ? 'translate(0, 1.5px) scale(0.75)' : 'translate(18px, 24px) scale(1)';
  }

  useEffect(() => {
    if( typeProp )
      console.log('MyTextField received property type.  Ignoring it. Value:', typeProp);
  },[typeProp]);

  return (
    <TextField
      ref={refTextField}
      type='text'
      inputRef={ inptRef => {
        setInputRef(inptRef);
        if( inputRefProp )
          inputRefProp(inptRef);
      }}
      onChange={(e) => {
        e.stopPropagation();
        setTouched(true);
        if( onChange )
          onChange(e);
        if( onValueChange )
          onValueChange(e.target.value);
      }}
      onFocus={(e) => {
        setI((p) => p+1);
        if( onFocusProp )
          onFocusProp(e);
      }}
      onBlur={(e) => {
        setI((p) => p+1)
        if( onBlurProp )
          onBlurProp(e);
      }}
      className={clsx(classes.field, classNameProp)}
      error= { touched && errorText ? true : false }
      helperText={touched && errorText}
      {...otherProps}
    />
  )
});

export default MyTextField;