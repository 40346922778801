import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import MyTextField from './MyTextField';

const styles = (theme) => ({
  field: {
    width: '150px',
  }
});
const useStyles = makeStyles(styles);

export const PhoneTextField = React.forwardRef(({value, onValueChange, className: classNameProp, ...otherProps}, ref) => {
  const classes = useStyles();

  return (
    <NumberFormat 
        value={value || ''}
        onValueChange={(values) => { if( onValueChange ) onValueChange(values.formattedValue) } }
        format='###-###-####'
        customInput={MyTextField}
        className={clsx(classes.field, classNameProp)}
        {...otherProps}
      />
  )
});



export default PhoneTextField;