// React
import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const styles = (theme) => ({
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
});
const useStyles = makeStyles(styles);


export const CheckboxField = ({value, onValueChange, className: classNameProp, ...otherProps}) => {
  const classes = useStyles();

  if( typeof value !== 'boolean' )
    throw new Error('Value property must be of type boolean.');

  const handleOnChangevent = (event) => {
    event.stopPropagation();
    if( onValueChange )
      onValueChange(event.target.checked);
  }

  return (
      <FormControlLabel
        control={
            <Checkbox checked={value} 
                      onChange={(event) => handleOnChangevent(event)}  />  }
        className={clsx(classes.field, classNameProp)}
        {...otherProps}
      />
  );
}








