import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import clsx from 'clsx';
import { DateTextField } from '.';

const styles = (theme) => ({
  field: {
    width: '150px',
  }
});
const useStyles = makeStyles(styles);


export const DateField = ({value, onValueChange, mode: modeProp, className: classNameProp, errorText, ...otherProps}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const mode = modeProp || (isXS ? 'touch' : 'keyboard');
  const [keyboardValue, setKeyboardValue] = useState('');    // Stored as "MM/DD/YYYY"

  useEffect(() => {
    if( mode === 'keyboard' ) {
      const mom = moment(value, 'YYYYMMDD', true);
      if( mom.isValid() )
        setKeyboardValue(mom.format('MM/DD/YYYY'));
      else
        setKeyboardValue('');
    }
  }, [value, mode]);

  if( mode === 'keyboard' ) {

    const handleOnChange = (value) => {
      setKeyboardValue(value)
      // If it is blank or valid, call onChange
      if( onValueChange ) {
        if( value === '' )
          onValueChange('');
        else {
          const mom = moment(value, 'MM/DD/YYYY', true);
          if( mom.isValid() )
            onValueChange(mom.format('YYYYMMDD'));
        }
      }
    }

    return (
      <DateTextField
          value={ keyboardValue }
          onValueChange={ (value) => handleOnChange(value) }
          className={clsx(classes.field, classNameProp)}
          errorText={errorText}
          {...otherProps}
          />
    );
  }
  else if( mode === 'touch' || mode === '' || mode === undefined || mode === null ) {
    const mom = moment(value, 'YYYYMMDD', true);

    return (
      <DatePicker
        value={ mom.isValid() ? mom : null }
        onChange={ (date, value) => { if( onValueChange ) onValueChange( date === null ? '' : date.format('YYYYMMDD') ) } }
        variant='standard'
        format='MM/DD/YYYY'
        autoOk = 'true'
        clearable={true}
        className={clsx(classes.field, classNameProp)}
        {...otherProps}
      />
    );
  }
  else
    throw new Error('Invalid mode ' + mode + '.');

}
